div,
h1,
h2,
h3,
h4,
h5,
h6 {
    @media (--media--md) {
        &.headline--short {
            max-width: 75%;
        }
    }
    &.headline {
        font-family: var(--font--base-family);
        font-weight: 900;
        hyphens: none;
        word-break: break-word;

        span:not(.nbsp, .shy) {
            display: block;
        }

        u {
            text-decoration: none;
            color: var(--color--accent);
        }
    }
}

body.neos-backend {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &.headline {
            text-transform: none !important;
        }
    }
}
