/* prettier-ignore */

.section {
    --outside-margin: 0;
    --outside-width: var(--spacing-column--md);
    --main-width: 1fr;

    display: grid;
    grid-template-columns:
                            [site-start]
                                var(--outside-margin)
                                    [page-start]
                                        var(--outside-width)
                                        [main-start]
                                            var(--main-width)
                                            [center-left]
                                                calc(var(--spacing-column--lg) * 0.5)
                                                [center]
                                                calc(var(--spacing-column--lg) * 0.5)
                                            [center-right]
                                            var(--main-width)
                                        [main-end]
                                        var(--outside-width)
                                    [page-end]
                                var(--outside-margin)
                            [site-end];
    grid-template-rows: auto;
    grid-auto-rows: auto;

    @media (--media--sm) {
        --outside-width: var(--spacing-column--lg);
    }

    @media (--media--lg) {
        --outside-width: 1fr;
        --main-width: 4fr;
    }

    @media (--media--xl) {
        --main-width: 2fr;
    }

    @media (--media--2xl) {
        --main-width: 1.25fr;
        --outside-margin: 0.5fr;
    }

    .grid-area-\[site\] {
        grid-area: site;
    }
    .grid-area-\[page\] {
        grid-area: page;
    }
    .grid-area-\[main\] {
        grid-area: main;
    }
}
