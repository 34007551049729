header.header {
    position: sticky;
    top: 0;
    z-index: 9999;
    background: var(--color--dark);
    box-shadow: 0px 25px 20px var(--color--dark);

    div.header--inner {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        padding: var(--spacing--xs) 0;
        border-bottom: 1px solid var(--color--primary);
        grid-area: main;

        @media (--media--lg) {
            justify-content: flex-start;
            padding: 0;
        }

        .logo {
            display: flex;
            align-items: center;
            width: 5rem;

            a {
                display: block;
                position: relative;

                @media (hover: hover) {
                    &:hover {
                        svg {
                            transition: 0.25s all;
                            fill: var(--color--primary);
                        }
                    }
                }

                @media (--media--md) {
                    padding: 0;
                }

                svg {
                    display: block;
                    width: 4rem;
                    transition: 0.25s all;
                    fill: var(--color--light);

                    @media (--media--md) {
                        width: 5rem;
                    }
                }

                span.sr-only {
                    width: 0;
                    height: 0;
                    position: absolute;
                    overflow: hidden;
                }
            }
        }
    }
}
