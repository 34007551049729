/*###############################################################################
#                                    Assets                                     #
###############################################################################*/
/* 1. base */
@import "../Assets/base/_font-face.pcss";
@import "../Assets/base/_base.pcss";
@import "../Assets/base/_normalize.pcss";

/* 2. config */
@import "../Assets/config/_config.pcss";

/* 3. global */
@import "../Assets/global/_colors.pcss";
@import "../Assets/global/_cookieconsent.pcss";
@import "../Assets/global/_font-size.pcss";
@import "../Assets/global/_margin.pcss";
@import "../Assets/global/_padding.pcss";

/*###############################################################################
#                                  Components                                   #
###############################################################################*/
/* Atom */
@import "Component/Atom/ButtonCollection/_buttonCollection.pcss";
@import "Component/Atom/ButtonCollection/Item/_button.pcss";
@import "Component/Atom/CardCollection/_cardCollection.pcss";
@import "Component/Atom/Headline/_headline.pcss";
@import "Component/Atom/Media/_media.pcss";
@import "Component/Atom/Paragraph/_paragraph.pcss";
@import "Component/Atom/Section/_section.pcss";
@import "Component/Atom/Text/_text.pcss";
@import "Component/Atom/JobOutputCollection/Item/_jobOutputCollectionItem.pcss";
@import "Component/Atom/NewsOutputCollection/Item/_newsOutputCollectionItem.pcss";

/* Molecule */
@import "Component/Molecule/CardContainer/_cardContainer.pcss";
@import "Component/Molecule/HistoryContainer/_historyContainer.pcss";
@import "Component/Molecule/HistoryContainer/Item/_historyContainerItem.pcss";
@import "Component/Molecule/CardContainer/Item/_cardContainerItem.pcss";
@import "Component/Molecule/EventContainer/Item/_eventContainerItem.pcss";
@import "Component/Molecule/FormContainer/Fragments/Select/_select.pcss";
@import "Component/Molecule/FormContainer/Fragments/Upload/_upload.pcss";
@import "Component/Molecule/FormContainer/_formJob.pcss";
@import "Component/Molecule/FormContainer/_formContact.pcss";
@import "Component/Molecule/IconContainer/_iconContainer.pcss";
@import "Component/Molecule/Info/_info.pcss";
@import "Component/Molecule/Text/_text.pcss";
@import "Component/Molecule/IconContainer/Item/_iconContainerItem.pcss";
@import "Component/Molecule/HeroBanner/_heroBanner.pcss";
@import "Component/Molecule/JobBanner/_jobBanner.pcss";
@import "Component/Molecule/HeroBanner/Item/_heroBannerItem.pcss";
@import "Component/Molecule/JobBanner/Item/_jobBannerItem.pcss";
@import "Component/Molecule/TextMedia/_textMedia.pcss";
@import "Component/Molecule/TextMediaCards/_textMediaCards.pcss";

/* Organism */
@import "Component/Organism/Footer/_footer.pcss";
@import "Component/Organism/Footer/Navigation/_navigation.pcss";
@import "Component/Organism/Header/_header.pcss";
@import "Component/Organism/Header/Navigation/_navigation.pcss";
@import "Component/Organism/Header/Navigation/Burger/_burger.pcss";

/* Template */
@import "Component/Template/_news.pcss";

@import "../../../../../node_modules/swiper/swiper.css";
