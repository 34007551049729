.section--textmedia {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media (--media--md) {
        flex-direction: row;
    }

    .text--content {
        width: 100%;
        padding: var(--spacing--md);
        grid-area: main;
        align-self: center;

        @media (--media--md) {
            width: 50%;
        }

        h,
        p {
            hyphens: none !important;
        }
    }

    .media--content {
        width: 100%;
        height: 200px;
        min-height: 0px;
        padding: 0;
        grid-area: page;
        align-self: stretch;

        @media (--media--md) {
            width: 50%;
            min-height: 600px;
            padding: var(--spacing--md);
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
}
