.job--hero {
    justify-content: center;
    height: 100%;
    padding-bottom: var(--spacing--lg);
    margin-top: var(--spacing--lg);

    @media (--media--lg) {
        padding-bottom: 0;
    }

    .inner--container {
        display: flex;
        flex-direction: column;

        .text--content {
            width: 100%;
            padding: 0;

            @media (--media--md) {
                width: 50%;
            }
        }

        .media--content {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: relative;
            padding: 0;
            margin-bottom: var(--spacing--md);

            @media (--media--md) {
                justify-content: right;
                width: 50%;
                margin-bottom: 0;
            }

            picture {
                width: 80%;
            }

            .gradient {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                background: rgba(38, 9, 2, 0);
                background: linear-gradient(
                    0deg,
                    var(--color--dark) 0%,
                    rgba(255, 255, 255, 0) 46%
                );
            }
        }

        .section--stageimage {
            display: flex;
            height: auto;
            overflow: hidden;

            .swiper-wrapper {
                display: flex;
            }
        }
    }
}
