.section--text {
    padding: var(--spacing--md) 0;

    h1 {
        font-size: var(--headline--xl);
        line-height: var(--headline--xl-height);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--color--primary);
    }

    .text {
        grid-area: main;
    }

    &:first-child {
        padding-top: var(--spacing--lg);
    }

    &:last-child {
        padding-bottom: var(--spacing--lg);
    }
}
