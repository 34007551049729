.gdpr-cookieconsent-container {
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.gdpr-cookieconsent-container {
    --cookieconsent-theme-color: var(--color--primary);
    --cookieconsent-overlay-color: rgb(0 0 0 / 0.5);
    --cookieconsent-bg-color: var(--color--dark2);

    --cookieconsent-group-bg-color: var(--color--dark2);

    --cookieconsent-button-text-color: var(--color--light);
    --cookieconsent-button-primary-color: var(--cookieconsent-theme-color);
    --cookieconsent-button-secondary-color: rgb(255 255 255);
    --cookieconsent-button-primary-text-color: var(
        --cookieconsent-button-text-color
    );
    --cookieconsent-button-secondary-text-color: var(--color--font);
    --cookieconsent-button-borderradius: 0;

    --cookieconsent-spacing: 1rem;
    --cookieconsent-width: 600px;

    --cookieconsent-focus-outline: transparent;
    --cookieconsent-focus-outline-offset: 0;

    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    background: rgba(0, 0, 0, 0.5) !important;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    float: unset;
    backdrop-filter: blur(10px);

    &::before {
        display: none;
    }

    &:focus-visible {
        outline: transparent;
    }

    .gdpr-cookieconsent-settings {
        position: absolute;
        top: var(--spacing--lg);
        top: 50%;
        left: 50%;
        background: var(--color--dark);
        border: 5px solid var(--color--primary);
        transform: translate(-50%, -50%);

        @media (max-width: 500px) {
            width: 90%;
        }

        .hidden {
            display: none !important;
        }

        .gdpr-cookieconsent-settings__content {
            display: flex;
            flex-direction: column;
            padding: calc(var(--spacing--md) * 0.75);
            padding-top: var(--spacing--md);

            @media (--media--sm) {
                padding: calc(var(--spacing--lg) * 0.75);
                padding-top: var(--spacing--lg);
            }

            .gdpr-cookieconsent-settings__content__info {
                display: block;
                order: 1;
                font-family: var(--font--base-family);
                hyphens: auto;
                padding-bottom: var(--spacing--md);

                h2 {
                    font-size: var(--headline--lg);
                    color: var(--color--headline);
                    text-align: center;
                }

                .gdpr-cookieconsent-settings__content__info__description {
                    display: block;
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    margin-bottom: var(--spacing--xs);

                    p {
                        hyphens: auto;
                        text-align: center;
                    }

                    &.gdpr-cookieconsent-settings__content__info__description--settings {
                        display: none;
                    }
                }
            }

            .gdpr-cookieconsent-settings__content__settings {
                order: 2;
                margin-top: 0;
                margin-bottom: var(--spacing--sm);

                .gdpr-cookieconsent-switch--group {
                    font-size: var(--headline--xs);

                    .gdpr-cookieconsent-switch--cookie {
                        font-size: var(--text--base);
                    }
                }
            }

            .gdpr-cookieconsent-settings__content__cta {
                display: flex;
                flex-direction: column;
                align-items: center;
                order: 3;
                margin: 0 !important;

                .gdpr-cc-button {
                    display: block;
                    width: fit-content;
                    position: relative;
                    font-weight: 900;
                    text-transform: uppercase;
                    color: var(--color--primary);
                    background: var(--color--dark2);
                    padding: calc(var(--spacing--sm) / 1.5) var(--spacing--sm);
                    margin-bottom: var(--spacing--md);
                    border: 3px solid var(--color--primary);
                    border-radius: 0px;
                    transform: scale(1);
                    transition: all 0.25s var(--easing--in-out);

                    @media (max-width: 500px) {
                        white-space: normal;
                    }

                    @media (hover: hover) {
                        &:hover {
                            background: var(--color--primary2);
                        }
                    }
                }

                .gdpr-cc-button--secondary,
                #gdpr-cc-btn-accept-necessary {
                    font-weight: 500;
                    text-transform: none;
                    color: var(--color--font);
                    background: none !important;
                    margin: 0;
                    border: none;
                    transition: all 0.35s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            color: var(--color--primary);
                            transform: scale(1);
                        }
                    }
                }
            }

            .gdpr-cookieconsent-settings__content__links {
                order: 4;
                margin-top: var(--spacing--md);
                text-align: center;

                a {
                    font-weight: 400;
                    transition: color 0.35s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            color: var(--color--accent);
                        }
                    }
                }

                span {
                    font-weight: 500;
                }
            }
        }
    }
}

.gdpr-cookieconsent-switch {
    --cookieconsent-switch-thumb-size: 1.25rem;
    --cookieconsent-switch-thumb-color: rgb(255 255 255);
    --cookieconsent-switch-thumb-highlight-color: rgba(0 0 0 / 0.25);
    --cookieconsent-switch-length: calc(
        var(--cookieconsent-switch-thumb-size) * 2
    );
    --cookieconsent-switch-padding: 4px;
    --cookieconsent-switch-inactive-color: var(color--grey);
    --cookieconsent-switch-active-color: var(--cookieconsent-theme-color);
    --cookieconsent-switch-thumb-size-small: 1rem;
    --cookieconsent-switch-outline: var(--cookieconsent-focus-outline);
    --cookieconsent-switch-outline-offset: var(
        --cookieconsent-focus-outline-offset
    );
}
