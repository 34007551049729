/* font-size text */
.text--xs {
    font-size: var(--text--xs);
    line-height: var(--text--xs-height);
}
.text--sm {
    font-size: var(--text--sm);
    line-height: var(--text--sm-height);
}
.text--base {
    font-size: var(--text--base);
    line-height: var(--text--base-height);
}

/* font-size headline */
.headline--xs {
    font-size: var(--headline--xs);
    line-height: var(--headline--xs-height);
}
.headline--sm {
    font-size: var(--headline--sm);
    line-height: var(--headline--sm-height);
}
.headline--md {
    font-size: var(--headline--md);
    line-height: var(--headline--md-height);
}
.headline--lg {
    font-size: var(--headline--lg);
    line-height: var(--headline--lg-height);
}
.headline--xl {
    font-size: var(--headline--xl);
    line-height: var(--headline--xl-height);
}
