section#form-contact {
    .form-contact--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: var(--spacing--md);
        gap: var(--spacing--md);

        @media (--media--md) {
            flex-direction: row;
            gap: var(--spacing--xl);
        }
    }

    .form {
        width: 100%;
    }

    .text {
        width: 100%;

        @media (--media--md) {
            width: 80%;
        }
    }
}
