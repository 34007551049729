.form--upload {
    --line: #ffffff7c;
    position: relative;

    @media (--media--md) {
        grid-column-end: span 2;
    }

    .field--upload-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: var(--spacing--xs);
        border: 2px solid var(--line);
        cursor: pointer;
        transition: all 0.25s var(--easing--in-out);

        svg {
            fill: red;
        }

        div.field--upload-dropzone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: var(--text--base);
            line-height: var(--text--base-height);
            font-weight: 900;
            color: var(--color--font);
            background: var(--color--grey);
            padding: var(--spacing--md) 0;
            border: none;
            cursor: pointer;
            transition: 0.25s all;
            text-align: center;
            gap: var(--spacing--sm);

            @media (--media--md) {
                flex-direction: row;
            }

            @media (hover: hover) {
                &:hover {
                    background: var(--color--primary2);
                    transition: 0.25s all;
                }
            }

            img.uploadIcon {
                display: inline-block;
                width: auto;
                height: 2rem;
            }

            &.active {
                background-color: var(--color--primary2);
                transition: 0.25s all;
            }
        }

        input.field--upload-input {
            display: none;
            width: 1px;
            height: 1px;
            position: absolute;
            white-space: nowrap;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            border: 0;
            clip: rect(0 0 0 0);
        }

        ul.field--upload-filelist {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            gap: var(--spacing--xs);

            li {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-size: var(--text--button);
                font-weight: 400;
                background: var(--color--dark2);
                padding: var(--spacing--sm) var(--spacing--md);

                .file--name {
                    display: flex;
                    flex-direction: column;
                    max-width: 100%;

                    span {
                        hyphens: auto;
                        margin-right: var(--spacing--md);
                    }

                    .field--upload-filename {
                        color: var(--color--primary);
                    }

                    .field--upload-filesize {
                        font-size: var(--text--sm);
                        color: var(--line);
                        margin-top: 5px;
                    }
                }

                .delete--button {
                    @media (hover: hover) {
                        &:hover {
                            svg {
                                stroke: var(--color--primary);
                            }
                        }
                    }

                    svg {
                        display: block;
                        width: 30px;
                        height: 30px;
                        transition: all 0.35s;
                        justify-self: right;
                        stroke: var(--color--font);
                        stroke-width: 2px;
                        fill: none;
                    }
                }
            }
        }
    }
}
