.field--select {
    position: relative;

    select {
        width: 100%;
        font-size: var(--font--base-size);
        line-height: var(--headline--sm-height);
        font-weight: 700;
        color: var(--color--font);
        background: var(--color--dark2);
        padding: var(--spacing--xs);
        border: 1px solid rgba(256, 256, 256, 0);
        cursor: pointer;
        transition: all 0.35s var(--easing--in-out);
        appearance: none;
        border-bottom-color: var(--color--primary);
    }

    span {
        display: flex;
        align-items: center;
        width: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: var(--spacing--xs);
        pointer-events: none;

        svg {
            display: block;
            width: 20px;
            height: auto;
        }
    }
}
