div.button--collection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: var(--spacing--sm);
}

.button-up--collection {
    display: block;
    position: fixed;
    bottom: var(--spacing--md);
    left: 0;
    right: 0;
    z-index: 9000;
    pointer-events: none;

    .button--collection {
        justify-content: center;

        .button--up {
            opacity: 0;
            pointer-events: none;

            &.active {
                opacity: 1;
                pointer-events: visible;
            }
        }
    }
}