footer {
    margin-top: var(--spacing--lg);

    ul.navigation--list {
        li.navigation--listitem {
            margin-bottom: var(--spacing--xs);
        }

        li.navigation--listitem,
        li.subnavigation--listitem {
            list-style-type: none;
            display: block;

            a {
                font-size: var(--text--base);
                transition: color 0.35s var(--easing--in-out);

                @media (hover: hover) {
                    &:hover {
                        color: var(--color--primary);
                    }
                }
            }

            &.current {
                a.navigation--listitem--link {
                    font-weight: 900;
                }
            }
        }

        li.subnavigation--listitem {
            a {
                font-size: var(--text--xs);
            }

            &.active {
                a.subnavigation--listitem--link {
                    font-weight: 900;
                }
            }
        }
    }

    h4 {
        font-size: 1.25rem;
        color: var(--color--primary);
        margin-bottom: var(--spacing--md);
    }

    div.footer--grid-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-row-gap: var(--spacing-column--lg);
        grid-column-gap: calc(var(--spacing-column--lg) * 2);
        padding-top: var(--spacing--lg);
        padding-bottom: var(--spacing--lg);
        grid-row: 2;

        @media (--media--md) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
            grid-row-gap: calc(var(--spacing-column--lg) * 2);
        }

        @media (--media--lg) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(1, auto);
            grid-row-gap: 0;
        }

        div.footer--text {
            display: flex;
            flex-direction: column;

            div.paragraph {
                strong {
                    display: inline-block;
                    margin-bottom: var(--spacing--xs);
                }
            }

            div.social--media {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: var(--spacing--md);
                gap: var(--spacing--xs);

                a {
                    @media (hover: hover) {
                        &:hover {
                            svg {
                                fill: var(--color--primary);
                            }
                        }
                    }
                    svg {
                        display: block;
                        max-width: var(--spacing--sm);
                        height: var(--spacing--sm);
                        transition: fill 0.35s var(--easing--in-out);
                        fill: var(--color--font);
                    }

                    span.sr-only {
                        width: 0;
                        height: 0;
                        position: absolute;
                        overflow: hidden;
                    }
                }
            }
        }

        nav.navigation--footer {
            @media (--media--md) {
                padding-top: 0;
            }
        }
    }
}
