.news--collection {
    display: grid;
    grid-template-columns: 1fr, 1fr, 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: var(--spacing--md);
    grid-column-gap: var(--spacing--md);

    @media (--media--md) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
    }

    img {
        transform: scale(1);
        transition: all 0.35s;
    }

    .line {
        width: 30px;
        transition: all 0.35s;
    }

    .news--item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        background: var(--color--dark2);
        transition: all 0.35s;

        @media (hover: hover) {
            &:hover {
                background: var(--color--primary2);

                .line {
                    width: 50px;
                    right: 20px;
                }

                svg {
                    right: 15px;
                }
            }
        }

        .media--box {
            width: 100%;
            height: 200px;
            overflow: hidden;
            grid-column: space;

            img {
                width: 100%;
                height: 100%;
                vertical-align: middle;
                object-fit: cover;
            }
        }

        .content--box {
            .content--inner {
                padding: var(--spacing--md);

                .date {
                    width: fit-content;
                    font-weight: 700;
                    color: var(--color--primary);
                }

                .title {
                    width: fit-content;
                    font-weight: 700;
                    color: var(--color--light);
                }

                .link {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: var(--spacing--md);

                    .button--text {
                        display: inline-block;
                        width: fit-content;
                        font-size: var(--text--button);
                        line-height: var(--text--sm-height);
                        font-weight: 900;
                        color: var(--color--primary);
                        margin-right: var(--spacing--md);
                    }

                    .line {
                        display: inline-block;
                        height: 2px;
                        background: var(--color--primary);
                    }

                    svg {
                        --size: 20px;
                        display: inline-block;
                        width: var(--size);
                        height: var(--size);
                        margin-left: -15px;
                        stroke: var(--color--primary);
                        fill: none;
                        stroke-width: 1.5px;
                    }
                }
            }
        }
    }
}
