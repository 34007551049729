div.paragraph {
    hyphens: none;
    word-break: break-word;

    &.fc--light {
        ul {
            li {
                &::before {
                    background: var(--color--light);
                }
            }
        }
    }

    ul {
        li {
            list-style-type: none;
            position: relative;
            padding-left: var(--spacing--sm);

            &::before {
                content: "";
                width: 5px;
                height: 5px;
                position: absolute;
                top: calc(var(--text--base) * 0.6);
                left: 0;
                background: var(--color--primary);
            }
        }
    }

    ol {
        list-style-type: none;
        counter-reset: number;

        li {
            display: flex;
            align-items: baseline;
            position: relative;
            margin-bottom: 0.25rem;

            &::before {
                content: counter(number);
                z-index: 1;
                font-size: var(--text--base);
                line-height: var(--text--base-height);
                font-weight: 900;
                color: var(--color--font);
                padding-right: var(--spacing--xs);
                counter-increment: number;
            }
        }
    }

    a {
        display: inline-block;
        text-decoration: underline;
        color: var(--color--primary);
    }
}
