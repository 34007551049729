html {
    font-size: var(--font--base-size);
    scroll-behavior: smooth;
    scroll-padding-top: var(--spacing--lg);

    @media (--media--sm) {
        scroll-padding-top: var(--spacing--xl);
    }
}

body {
    font-family: var(--font--base-family);
    font-size: var(--font--base-size);
    font-weight: 500;
    color: var(--color--font);
    background: var(--color--dark);
    overflow-x: hidden;
    overflow-y: scroll;

    a {
        cursor: pointer !important;
    }
}

.neos-backend {
    .neos-contentcollection {
        & > div {
            width: 100%;
        }

        & > [class*="addEmptyContentCollectionOverlay"] {
            height: auto;
            min-height: 30px;
            outline-style: dashed;

            &::before {
                content: "Please add your child items here.";
                display: block;
                color: #adadad;
                padding: var(--spacing--xs);
            }
        }
    }
}

::selection {
    text-shadow: none;
    color: var(--color--light);
    background: var(--color--primary);
}

.bg--primary,
.bg--secondary {
    ::selection {
        text-shadow: none;
        color: var(--color--primary);
        background: var(--color--light);
    }
}
