.section--cards {
    grid-template-rows: max-content min-content max-content;

    .text,
    .buttons {
        grid-column-start: main-start;
        grid-column-end: main-end;
    }

    .section--title {
        width: 80%;
        justify-self: center;

        .paragraph {
            text-align: center;
        }

        .supline {
            text-align: center;
        }

        .headline {
            text-align: center;
        }
    }

    .buttons {
        grid-row-start: 3;
        justify-self: center;
    }
}
