.event--item {
    --svgSize: 24px;
    display: grid;
    grid-template-columns: 1fr var(--svgSize);
    align-items: center;
    padding: var(--spacing--md) 0;
    border-bottom: 3px solid var(--color--light);
    transform: scale(1);
    transition: transform .35s var(--easing--in-out);
    row-gap: 0.25rem;

    @media(--media--md) {
        grid-template-columns: 3fr 4fr 2fr 3fr var(--svgSize);
        grid-template-rows: auto;
        column-gap: var(--spacing-column--md);

        .date {
            grid-column-start: 1;
        }
        .title {
            grid-column-start: 2;
        }
        .city {
            grid-column-start: 3;
        }
        .location {
            grid-column-start: 4;
        }

        svg {
            grid-row-end: span 1;
        }
    }

    &.event-in-past {
        opacity: 25%;

        @media (hover: hover) {
            &:hover {
                transform: unset !important;
            }
        }
    }

    .date,
    .title,
    .city,
    .location,
    svg {
        grid-column-start: 1;
        grid-column-end: span 1;
    }


    svg {
        display: block;
        grid-column-start: 5;
        grid-row-start: 1;
        grid-row-end: span 4;
        width: var(--svgSize);
        height: var(--svgSize);
        fill: var(--color--light);

        &.rotation {
            transform: rotate(-45deg);
        }
    }
}

a.event--item {
    @media (hover: hover) {
        &:hover {
            transform: scale(1.025);
        }
    }
}
