.history {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: auto;
    background: var(--color--dark);
    padding: var(--spacing--md) 0;
    gap: var(--spacing--xs);

    @media (--media--md) {
        flex-direction: row;
        padding: var(--spacing--md) 0;
        gap: var(--spacing--xs);
    }

    &:nth-child(2n) {
        .media--container {
            order: 5;

            @media (--media--md) {
                order: 1;
            }
        }

        .content--container {
            order: 1;

            @media (--media--md) {
                order: 5;
            }
        }
    }

    .media--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 5;
        width: 100%;

        @media (--media--md) {
            width: 50%;
        }

        figure.media--content {
            width: 100%;
            height: auto;
            position: relative;
            z-index: 0;

            img {
                display: block;
                width: 100%;
                height: auto;
                z-index: 0;
                object-fit: contain;
            }

            span.gradient {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                z-index: 100;
                background: rgba(38, 9, 2, 0);
                background: linear-gradient(
                    0deg,
                    var(--color--dark) 10%,
                    rgba(255, 255, 255, 0) 60%
                );
            }
        }
    }

    div.content--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 1;
        width: 100%;
        position: relative;
        z-index: 2;
        padding: var(--spacing-column--md) 0 0 0;

        @media (--media--md) {
            width: 50%;
            padding: 0 var(--spacing-column--md);
        }

        /* @media (--media--2xl) {
            width: 75%;
        } */

        h6 {
            font-size: 75px;
            font-weight: 700;
            color: var(--color--primary);

            @media (--media--md) {
                left: 25px;
            }
        }

        h3.headline {
            text-transform: none !important;
        }

        div.link {
            display: flex;
            align-items: center;
            padding-top: var(--spacing--md);

            span.button--text {
                display: inline-block;
                width: fit-content;
                font-size: var(--text--button);
                line-height: var(--text--sm-height);
                font-weight: 900;
                color: var(--color--primary);
                margin-right: var(--spacing--md);
            }

            .line {
                display: inline-block;
                height: 2px;
                background: var(--color--primary);
            }

            svg {
                --size: 20px;
                display: inline-block;
                width: var(--size);
                height: var(--size);
                margin-left: -15px;
                stroke: var(--color--primary);
                fill: none;
                stroke-width: 1.5px;
            }
        }
    }
}

body.neos-backend {
    .card {
        .link span {
            text-transform: none !important;
        }
    }
}
