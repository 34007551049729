div.text {
    grid-row-start: 1 !important;

    u {
        text-decoration: none;
        color: var(--color--primary);
    }

    td {
        padding-right: var(--spacing--sm);
    }

    .supline {
        font-weight: 700;
        text-transform: uppercase;
    }
}

body.neos-backend {
    .supline {
        text-transform: none !important;
    }
}
