.button {
    display: block;
    width: fit-content;
    position: relative;
    font-weight: 900;
    text-transform: uppercase;
    padding: calc(var(--spacing--xs) * 0.6) var(--spacing--sm);
    border: 3px solid var(--color--primary);
    transform: scale(1);
    transition: all 0.25s var(--easing--in-out);

    &.button--primary {
        color: var(--color--primary2);
        background: var(--color--dark2);
        padding: calc(var(--spacing--sm) / 1.5) var(--spacing--sm);
        border: 2px solid var(--color--primary);

        @media (hover: hover) {
            &:hover {
                background: var(--color--primary2);
            }
        }

        &.bg--primary {
            color: var(--color--primary);
            background-color: var(--color--dark2);
        }

        &.bg--light {
            color: var(--color--primary);
        }
    }
    &.button--secondary {
        @media (hover: hover) {
            &:hover {
            }
        }

        &.bg--primary {
            color: var(--color--primary);
            background: var(--color--primary2);
        }
        &.bg--accent {
            color: var(--color--accent-0);
            background: rgba(221, 37, 202, 0.25);
        }
        &.bg--light {
            color: var(--color--light);
            background: rgba(255, 255, 255, 0.25);
        }
    }
}

body.neos-backend {
    .button {
        text-transform: none !important;
    }
}
