.job--collection {
    display: grid;
    grid-template-columns: repeat(1fr);
    grid-template-rows: 1fr;
    grid-row-gap: var(--spacing--md);
    grid-column-gap: var(--spacing--md);
    cursor: pointer;

    @media (--media--md) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
    }

    .job--item {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        background: var(--color--dark2);
        padding: var(--spacing--md) var(--spacing--sm);
        border: 2px solid var(--color--primary);
        transition: all 0.35s;

        @media (hover: hover) {
            &:hover {
                background: var(--color--primary2);

                .line {
                    width: 50px;
                    right: 20px;
                }

                svg {
                    right: 15px;
                }
            }
        }

        .job--title {
            width: fit-content;
            font-size: var(--text--button);
            font-weight: 700;
        }

        .line {
            display: inline-block;
            width: 30px;
            height: 2px;
            position: absolute;
            right: 40px;
            background: var(--color--primary);
            transition: all 0.35s;
        }

        svg {
            --size: 20px;
            display: inline-block;
            width: var(--size);
            height: var(--size);
            position: absolute;
            right: 35px;
            transition: all 0.35s;
            stroke: var(--color--primary);
            fill: none;
            stroke-width: 1.5px;
        }
    }
}
