form {
    grid-area: main;

    fieldset {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        gap: var(--spacing--sm);

        @media (--media--md) {
            grid-template-columns: repeat(2, 1fr);
        }

        .form--field {
            display: block;
            width: 100%;

            &.form--message {
                @media (--media--md) {
                    grid-column-end: span 2;
                }
            }

            label {
                font-size: 0.8rem;
                color: var(--color--primary);
            }

            input,
            textarea,
            select {
                display: block;
                width: 100%;
                font-family: var(--font--base-family);
                font-size: 16px;
                line-height: var(--headline--sm-height);
                font-weight: 400;
                text-transform: unset;
                color: var(--color--font);
                background-color: var(--color--dark2);
                outline: none;
                padding: var(--spacing--xs);
                margin-top: 5px;
                border: 1px solid transparent;
                border-radius: 0;
                border-bottom-color: var(--color--primary);

                &::placeholder {
                    color: var(--color--grey);
                }
                &:focus {
                    border-bottom-color: var(--color--font);
                }
            }

            textarea {
                height: 250px;
                background: var(--color--dark2);
                padding: var(--spacing--xs);
                resize: none;
                border: 1px solid transparent;
                border-bottom-color: var(--color--primary);

                &::placeholder {
                    color: rgba(255, 255, 255, 0.85);
                }

                &:focus {
                    border-bottom-color: var(--color--font);
                }
            }

            &.error {
                input,
                textarea,
                select {
                    border-bottom: 1px solid rgb(156, 10, 7);
                }

                ul.errors li {
                    list-style: none;
                    font-size: var(--text--xs);
                    font-weight: 900;
                    text-transform: uppercase;
                    color: rgb(156, 10, 7);
                    margin-top: var(--spacing--xs);
                }
            }

            &.form--checkbox {
                @media (--media--md) {
                    grid-column-end: span 2;
                }
                label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding-top: var(--spacing--xs);

                    input[type="checkbox"] {
                        width: auto;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        margin-right: 0.5rem;
                        border-radius: 0;
                        cursor: pointer;
                    }

                    span {
                        display: block;
                        order: 2;
                        font-size: 1.05rem;
                        line-height: 1.5;
                        color: var(--color--font);
                        padding-left: 5px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
