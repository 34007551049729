.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: var(--color--dark2);
    padding: var(--spacing--md);
    cursor: pointer;
    transition: all 0.35s;
    gap: var(--spacing--md);

    @media (--media--lg) {
        flex-direction: row;
    }

    @media (hover: hover) {
        &:hover {
            background: var(--color--primary2);
            .line {
                width: 100px;
            }

            img {
                transform: scale(1.05);
            }
        }
    }

    img {
        transform: scale(1);
        transition: all 0.35s;
    }

    .line {
        width: 30px;
        transition: all 0.35s;
    }

    figure.image--content {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 0;
        overflow: hidden;
        aspect-ratio: 16/9;

        @media (--media--lg) {
            width: 80%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            z-index: 0;
            object-fit: cover;
        }
    }

    div.content--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: relative;
        z-index: 2;
        padding: var(--spacing--md) 0 0 0;

        @media (--media--md) {
            padding: 0 var(--spacing---md);
        }

        /* @media (--media--2xl) {
            width: 75%;
        } */

        h3.headline {
            text-transform: none !important;
        }

        div.link {
            display: flex;
            align-items: center;
            padding-top: var(--spacing--md);

            span.button--text {
                display: inline-block;
                width: fit-content;
                font-size: var(--text--button);
                line-height: var(--text--sm-height);
                font-weight: 900;
                color: var(--color--primary);
                margin-right: var(--spacing--md);
            }

            .line {
                display: inline-block;
                height: 2px;
                background: var(--color--primary);
            }

            svg {
                --size: 20px;
                display: inline-block;
                width: var(--size);
                height: var(--size);
                margin-left: -15px;
                stroke: var(--color--primary);
                fill: none;
                stroke-width: 1.5px;
            }
        }
    }
}

body.neos-backend {
    .card {
        .link span {
            text-transform: none !important;
        }
    }
}
