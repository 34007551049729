.cards {
    --el--column-count: 1;
    grid-column-start: main-start;
    grid-column-end: main-end;
    grid-row-start: 2;

    @media (--media--md) {
        &.md\:columns-1 {
            --el--column-count: 2;
        }
    }
    @media (--media--lg) {
        &.lg\:columns-2 {
            --el--column-count: 1;

            .card {
                div.content--container {
                    width: 100% !important;
                }
            }
        }
    }

    .card--collection {
        display: grid;
        grid-template-columns: repeat(var(--el--column-count), 1fr);
        grid-template-rows: auto;
        gap: var(--spacing--md);
    }
}
