:root {
    /* colors */
    --color--primary: #00946d;
    --color--primary2: #222e23;
    --color--light: #fff;
    --color--dark: #131313;
    --color--dark2: #1e1e1e;
    --color--headline: #00946d;
    --color--font: #d2d2d2;

    /* typography */
    --font--base-family: Scada, sans-serif;
    --font--base-size: 16px;

    /* text-size*/
    /* xs */
    --text--xs: 0.75rem;
    --text--xs-height: 1.35;
    /* sm */
    --text--sm: 0.85rem;
    --text--sm-height: 1.35;

    /* base */
    --text--base: 1rem;
    --text--base-height: 1.5;

    /* button */
    --text--button: 1.15rem;
    --text--button-height: 1.5;

    /* headline-size*/
    /* xs */
    --headline--xs: 1rem;
    --headline--xs-height: 1.35;
    /* sm */
    --headline--sm: 1.25rem;
    --headline--sm-height: 1.35;
    /* md */
    --headline--md: 1.5rem;
    --headline--md-height: 1.2;
    /* lg */
    --headline--lg: 1.75rem;
    --headline--lg-height: 1.25;
    /* xl */
    --headline--xl: 3.35rem;
    --headline--xl-height: 1.25;

    /* spacing */
    --spacing--xs: 0.95rem;
    --spacing--sm: 1.25rem;
    --spacing--md: 1.875rem;
    --spacing--lg: 5rem;
    --spacing--xl: 10rem;

    /* grid */
    --spacing-column--md: 1.5rem;
    --spacing-column--lg: 3rem;

    /* radius */
    --radius--md: 100px;

    /* easing */
    --easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);

    @media (--media--md) {
        /* headline-size*/
        /* xs */
        --headline--xs: 1.125rem;
        --headline--xs-height: 1.25;
        /* sm */
        --headline--sm: 1.5rem;
        --headline--sm-height: 1.25;
        /* md */
        --headline--md: 1.875rem;
        --headline--md-height: 1.25;
        /* lg */
        --headline--lg: 2.25rem;
        --headline--lg-height: 1.25;
        /* xl */
        --headline--xl: 4rem;
        --headline--xl-height: 1.25;
    }
    @media (--media--lg) {
        /* grid */
        --spacing-column--md: 2.5rem;
        --spacing-column--lg: 3.5rem;
    }
}

/* breakpoints*/

@custom-media --media--sm (min-width: 576px);
@custom-media --media--md (min-width: 768px);
@custom-media --media--lg (min-width: 992px);
@custom-media --media--xl (min-width: 1300px);
@custom-media --media--2xl (min-width: 1800px);
