.iconCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 125px;

    figure.image--content {
        img {
            display: block;
            width: 100%;
            min-width: 100px;
            height: 100%;
            max-height: 150px;
            background: var(--color--light);
            object-fit: contain;
            border-radius: 150px;
        }
    }

    div.text--content {
        margin-top: var(--spacing--xs);

        &.link--content {
            display: grid;
            grid-template-columns: 25px 1fr;
            gap: calc(var(--spacing--xs) * 0.5);

            span {
                text-align: left !important;
            }
        }

        svg {
            display: inline-block;
            grid-column-start: 1;
            width: 100%;
            height: auto;
            fill: var(--color--primary);

            &.target--blank {
                transform: rotate(-45deg);
            }
        }

        span {
            display: inline-block;
            grid-column-start: 2;
            font-size: var(--text--base);
            line-height: 1.1;
            font-weight: 900;
            color: var(--color--font);
            margin-top: 4px;
            text-align: center;
        }
    }
}

a.iconCard {
    transform: scale(1);
    transition: transform 0.5s var(--easing--in-out);
    transform-origin: center;

    @media (hover: hover) {
        &:hover {
            transform: scale(1.1);
        }
    }
}
