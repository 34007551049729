.section--icon {

    div.icon--collection {

        display: flex;
        flex-wrap: wrap;
        padding-top: var(--spacing--md);
        gap: var(--spacing-column--lg);
        /* --el--column-count: 2;
        display: grid;
        grid-template-columns: repeat(var(--el--column-count), max-content);
        grid-template-rows: auto;

 */

        @media (--media--lg) {
            /* --el--column-count: 4; */
            justify-content: space-between;
        }
    }
}
