/* background-colors */
.bg--primary {
    background: var(--color--primary);
}
.bg--secondary {
    background: var(--color--secondary);
}
.bg--accent {
    background: var(--color--accent-0);
}
.bg--light {
    background: var(--color--light);
}
.bg--dark {
    background: var(--color--dark);
}
.bg--dark2 {
    background: var(--color--dark2);
}

/* font-colors */
.fc--headline {
    color: var(--color--headline);
}
.fc--font {
    color: var(--color--font);
}
.fc--light {
    color: var(--color--light);
}

-webkit-tap-highlight-color: rgba(255, 255, 255, 1);
