.section--history {
    grid-template-rows: max-content min-content max-content;

    .text {
        grid-column-start: main-start;
        grid-column-end: main-end;
    }

    .section--title {
        justify-self: center;
        text-align: center;

        .supline {
            text-transform: uppercase;
            text-align: center;
        }

        .headline {
            text-align: center;
        }

        .paragraph {
            text-align: center;
        }
    }
}
