/* prettier-ignore */

.section--news {

    .media--box {
        display: block;
            width: 100%;
            height: 400px;

        img {
            width: 100%;
            height: 400px;
            object-fit: cover;

        }

    }

    .news--content {
        display: block;

        .date {
            font-weight: 900;
        }



    }
}
