.section--info {
    padding: var(--spacing--md) 0;

    &:first-child {
        padding-top: var(--spacing--lg);
    }

    &:last-child {
        padding-bottom: var(--spacing--lg);
    }
}
