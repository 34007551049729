div.video--container {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.75);
    padding: var(--spacing--md);
    inset: 0;

    &.active {
        display: flex;
    }

    button {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 0;
        inset: 0;

        @media (hover: hover) {
            &:hover {
                div.icon--container {
                    background: var(--color--light);
                    transform: scale(0.95) rotate(-45deg);

                    span {
                        background: var(--color--primary);
                    }
                }
            }
        }

        div.icon--container {
            --size: 3rem;
            width: var(--size);
            height: var(--size);
            position: fixed;
            top: 10px;
            right: 10px;
            background: transparent;
            padding: var(--spacing--xs);
            border-radius: var(--radius--md);
            transform: scale(1) rotate(-45deg);
            transition: all 0.35s var(--easing--in-out);

            span {
                display: block;
                width: 1.5rem;
                height: 5px;
                position: absolute;
                top: 50%;
                left: 50%;
                background: var(--color--light);
                border-radius: 5px;
                transition: all 0.35s var(--easing--in-out);

                &:first-child {
                    transform: translate(-50%, -50%) rotate(-90deg);
                }

                &:last-child {
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    video {
        display: block;
        width: auto;
        max-width: 90vw;
        height: auto;
        max-height: 90vh;
        z-index: 10;
    }
}

.video--thumbnail--container {
    position: relative;
    cursor: pointer;

    &::before {
        content: url('../Images/play.svg');
        width: 100px;
        height: 100px;
        position: absolute;
        z-index: 10;
        margin: auto;
        inset: 0;
    }
}
