nav.navigation--header {
    grid-auto-rows: max-content;
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: height 0.5s var(--easing--out);
    row-gap: var(--spacing-column--lg);

    @media (--media--lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: initial !important;
        position: unset;
        top: initial;
        left: initial;
        right: initial;
        padding: var(--spacing--md) 0;
        padding-left: var(--spacing--md);
        margin-right: calc(var(--spacing--xs) * -1);
        overflow: unset;
        transition: none;
        gap: var(--spacing--xs);
    }

    &.active {
        height: auto;
        background: var(--color--dark);
        padding: var(--spacing--md) 0;
        overflow-y: auto;

        @media (--media--lg) {
            height: initial !important;
            padding-top: var(--spacing--xs);
            overflow-y: unset;
        }
    }

    /* Navigation */
    ul.navigation--list {
        display: flex;
        flex-direction: column;

        @media (--media--lg) {
            flex-direction: row;
            justify-content: flex-end;
            align-items: stretch;
            width: 100%;
            gap: calc(var(--spacing-column--md) * 0.25);
        }

        .navigation--listitem,
        .language--listitem {
            list-style-type: none;
            display: block;
            cursor: pointer;

            @media (--media--lg) {
                display: inline-flex;
                align-items: flex-end;
                position: relative;
            }

            .navigation--listitem--link,
            .language--listitem--link {
                display: inline-block;
                position: relative;
                z-index: 10;
                font-size: var(--text--base);
                line-height: var(--text--base-height);
                font-weight: 500;
                hyphens: auto;
                color: var(--color--light);
                padding: var(--spacing--xs) 0;
                border-radius: var(--radius--md);
                cursor: pointer;

                @media (--media--lg) {
                    font-size: var(--text--xs);
                    line-height: var(--text--xs-height);
                    padding: calc(var(--spacing--xs) * 0.25) var(--spacing--xs);
                }
            }
        }
    }

    ul.navigation--list {
        grid-row-start: 1;
        gap: calc(var(--spacing-column--md) * 0.5);

        .navigation--listitem {
            .navigation--listitem--link {
                font-size: var(--headline--sm);
                line-height: var(--headline--sm-height);
                font-weight: 900;
                transition: color 0.35s var(--easing--in-out),
                    background 0.35s var(--easing--in-out);

                @media (--media--lg) {
                    font-size: var(--text--sm);
                    line-height: var(--text--sm-height);
                }

                @media (hover: hover) {
                    &:hover {
                        color: var(--color--primary);
                    }
                }
            }
        }
    }
}
