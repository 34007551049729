.hero {
    display: grid;
    height: auto;
    margin-top: var(--spacing--md);

    @media (--media--md) {
        margin-top: var(--spacing--md);
    }
    @media (--media--lg) {
        margin-top: var(--spacing--lg);
    }

    .inner--container {
        display: flex;
        flex-direction: column;
        order: 1;
        position: relative;

        .swiperbutton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            position: absolute;
            top: calc(50%-25px);
            z-index: 10000;
            background-color: var(--color--dark2);
            border-radius: 100px;
            box-shadow: 0px 0px 20px var(--color--dark);
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    background: var(--color--primary2);
                    transition: 0.25s all;
                }
            }

            &.left {
                display: none;
                left: -75px;

                @media (--media--lg) {
                    display: flex;
                }

                &.hide-elements {
                    display: none;
                }
            }

            &.right {
                display: none;
                right: -75px;
                transform: rotate(-180deg);

                @media (--media--lg) {
                    display: flex;
                }

                &.hide-elements {
                    display: none;
                }
            }
        }

        .section--stageimage {
            display: flex;
            height: auto;
            overflow: hidden;

            .section--textmedia {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;

                @media (--media--md) {
                    flex-direction: row;
                }
            }

            .swiper-wrapper {
                display: flex;
                align-items: stretch;
            }
        }
    }

    .company--list {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--spacing--md);
        flex-direction: row;
        order: 2;
        width: 100%;
        margin-top: var(--spacing--md);

        /* Tablet: 2 Kacheln pro Reihe */
        @media (--media--md) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: var(--spacing--xs);
            margin-top: var(--spacing--xs);
        }

        /* Desktop: 1 Kachel pro Reihe */
        @media (--media--lg) {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: var(--spacing--xs);

            margin-top: var(--spacing--xs);
        }

        &.nodisplay {
            display: none;
        }

        .company {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 150px;
            background: var(--color--dark2);
            transition: all 0.35s;
            flex: 1;

            @media (hover: hover) {
                &:hover {
                    background: var(--color--primary2);

                    img {
                        filter: none;
                        transform: scale(1.05);
                    }
                }
            }

            img {
                width: auto;
                height: 125px;
                filter: grayscale(100%);
                transform: scale(1);
                transition: all 0.35s;
            }
        }
    }
}

.section--pagination {
    grid-row-start: 1;
    z-index: 10;
    align-self: end;

    &.swiper-pagination {
        display: flex;
        width: 100%;

        .swiper-pagination-bullet {
            display: flex;
            width: 100%;
            height: 7.5px;
            font-size: var(--text--sm);
            line-height: var(--text--sm-height);
            font-weight: 900;
            color: var(--color--light);
            background: var(--color--primary2);
            cursor: pointer;

            &.swiper-pagination-bullet-active {
                opacity: 1;
                background: var(--color--primary);
            }
        }
    }
}
