/* scada-regular - latin */
@font-face {
    font-style: normal;
    font-family: "Scada";
    font-weight: 400;
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    src: url("../fonts/scada-v15-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* scada-700 - latin */
@font-face {
    font-style: normal;
    font-family: "Scada";
    font-weight: 700;
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    src: url("../fonts/scada-v15-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
