    button.toggle--burger {
        display: inline-block;
        width: 2.25rem;
        padding: 0.25rem 0;
        cursor: pointer;
        touch-action: manipulation;

        @media (hover: hover) {
            &:hover {
                span:nth-child(3) {
                    width: 100%;
                }
            }
        }

        @media (--media--lg) {
            display: none;
        }

        span {
            display: block;
            width: 100%;
            height: 3px;
            background: var(--color--primary);
            border-radius: var(--radius--md);
            transition: all 0.25s var(--easing--in-out);

            &:first-child {
                transform: translateY(0) rotate(0);
            }

            &:nth-child(2) {
                margin-top: 5px;
            }

            &:last-child {
                width: 60%;
                margin-top: 5px;
                transform: translateY(0) rotate(0);
                margin-inline: auto;
            }
        }

        &.active {
            span {
                &:first-child {
                    transform: translateY(8px) rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                    width: 0;
                }

                &:last-child {
                    width: 100%;
                    transform: translateY(-8px) rotate(-45deg);
                }
            }
        }
    }
